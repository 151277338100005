<template>
  <div class="mapping-search-filter">
    <Dropdown
      class="mapping-search-filter__dropdown"
      :label="selectedSearchOptionLabel"
    >
      <DropdownItem
        v-for="option in searchOptions"
        :key="option.value"
        :label="option.label"
        clickable
        @click="selectSearchOption(option)"
      >
        {{ option.label }}
      </DropdownItem>
    </Dropdown>
    <TextInput
      class="mapping-search-filter__text-input"
      :model-value="searchQuery"
      @update:model-value="updateSearchQuery"
      icon="search"
      placeholder="Search"
      small
    />
  </div>
</template>

<script>
import { debounce, map, find } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import TextInput from '@/components/common/TextInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';

export default {
  components: {
    TextInput,
    Dropdown,
    DropdownItem,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const mappingPrefix = computed(() => {
      switch (route.name) {
      case 'eventsMapping':
        return 'Event';
      case 'competitorsMapping':
        return 'Competitor';
      case 'regionsMapping':
        return 'Region';
      case 'competitionsMapping':
        return 'Competition';
      case 'playersMapping':
        return 'Player';
      default:
        return 'N/A';
      }
    });
    const searchOptions = computed(() => {
      const options = map(
        store.getters.mappingSearchOptions,
        ({ label, value }) => ({
          value,
          label: `${mappingPrefix.value} ${label}`,
        }),
      );

      if (route.name === 'playersMapping') {
        options.push({
          value: 'index',
          label: `${mappingPrefix.value} Index`,
        });
      }

      return options;
    });
    const selectedSearchOption = computed(() => store.getters.mappingSelectedSearchOption);
    const selectedSearchOptionLabel = computed(() => find(searchOptions.value, { value: selectedSearchOption.value })?.label);
    const selectSearchOption = ({ value }) => {
      store.dispatch('setMappingSearchOption', value);
    };

    const searchQuery = computed(() => store.getters.mappingSearchQuery);
    const updateSearchQuery = debounce((newSearchQuery) => {
      store.dispatch('setMappingSearchQuery', newSearchQuery);
    }, 600);

    return {
      searchOptions,
      selectedSearchOption,
      selectedSearchOptionLabel,
      selectSearchOption,
      searchQuery,
      updateSearchQuery,
    };
  },
};
</script>

<style lang="scss" scoped>
.mapping-search-filter {
  display: flex;
  margin-right: 12px;
  border-radius: 4px;

  .mapping-search-filter__dropdown {
    min-width: 180px;
    max-width: 180px;
    width: 180px;

    .button {
      border-radius: 0;
    }
  }

  .mapping-search-filter__text-input {
    min-width: 180px;
    max-width: 180px;
    width: 180px;
  }
}
</style>
